<template>
  <v-row justify="center">
    <v-slide-y-transition appear>
      <template v-slot:heading>
        <div class="text-center">
          <h1 class="display-2 font-weight-bold mb-2">Login</h1>
        </div>
      </template>

      <v-card-text class="text-center card">
        <v-card-title class="justify-center title-card">
          <v-icon large color="red">vagas-home</v-icon>
          <h2>Seja bem-vindo!</h2>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="flex-column" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-filelds">E-mail</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="username"
                placeholder="willie.jennings@example.com"
                :rules="[(v) => !!v || 'E-mail requerido']"
                @keydown.enter="login"
                color="secondary"
                :messages="msgLabel"
                required
                outlined
                rounded
                autocomplete="email"
                class="input"
              />
            </v-col>
          </v-row>

          <v-row class="flex-column senha" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-filelds">Senha</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                placeholder="********"
                :rules="passwordRules"
                @keydown.enter="login"
                color="secondary"
                required
                outlined
                rounded
                class="input"
                autocomplete="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-btn
          :disabled="!valid"
          large
          rounded
          color="success"
          @click="login"
          :loading="loading"
          class="my-4 btn-login py-3"
        >
          <p style="color: white; margin-bottom: 0px">entrar</p>
        </v-btn>
      </v-card-text>
    </v-slide-y-transition>
  </v-row>
</template>

<style scoped>
.text-filelds {
  font-size: 1.2em;
}
.card {
  padding: 1.5rem 1.5rem 1.5rem !important;
  width: 400px;
}
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>

<script lang="js">
import  client        from  "../../../services/PluggyService";

export default {
  name                  : "Login",
  components            : {},

  data                  : () => ({
    show                : false,
    client,
    loading             : false,
    error               : false,
    valid               : true,
    documentNumber      : "",
    documentNumberRules : [(v) => !!v || "CPF requerido"],
    password            : "",
    passwordRules       : [(v) => !!v || "Senha requerida"],
    username            : undefined,
    password            : undefined,
    msgLabel            : "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async login() {
      if (this.validate()) {
        this.msgLabel = "";
        this.loading  = true;
        try {
          await this.client.doLogin(this.$store, this.username, this.password);
          this.$router.push("/home");
        } catch (reason) {
          this.msgLabel = reason && reason.body && reason.body.message || 'Erro não identificado';
          this.error    = true;
          setTimeout(() => (this.error = false), 3000);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>